const updateEndDate = (startDateTarget, endDateTarget) => {
  const parsedStartDate = Date.parse(startDateTarget.val());
  const parsedEndDate =  Date.parse(endDateTarget.val());
  const formatedStartDate =  moment(parsedStartDate).format('ddd, DD MMM YYYY');

  if (parsedEndDate < parsedStartDate) endDateTarget.datepicker('update', formatedStartDate);

  const startDateNumber = new Date(parsedStartDate);
  const endDateNumber = new Date(parsedEndDate);
  const daysRange = moment(endDateNumber).diff(startDateNumber, 'days') + 1;
  const weekDaysContainer = document.querySelector("#weekdays-container");
  
  if (weekDaysContainer) {
    weekDaysContainer.style = `display: ${daysRange < 7 ? 'none' : 'flex'};`;
  }
}

window.onChangeSpecialDate = () => {
  const startDateTarget = $('#special_hour_start_date');
  const endDateTarget = $('#special_hour_end_date');
  updateEndDate(startDateTarget, endDateTarget);
}

window.onChangeSpecialDateList = (event) => {
  const index = event.target.id.split('_').pop();
  const startDateTarget = $(`#special_hour_start_date_${index}`);
  const endDateTarget = $(`#special_hour_end_date_${index}`);
  updateEndDate(startDateTarget, endDateTarget);
}

window.adminSpecialDatesForm = ({ error }) => {
  $('.special_hour_allow_booking').hide();

  if (error) alert(error);

  const toggleFacilities = (event) => {
    $('select[name="special_hour[week_days][]').multiselect({
      allSelectedText: 'All',
      selectAllNumber: false,
      inheritClass: true,
      buttonClass: 'input-sm text-left'
    });

    let condition = null;

    if (event.target) {
      if (event.target.selectedOptions) {
        condition = event.target.selectedOptions.length !== 7;
      } else {
        condition = event.target.value === '0';
      }
    }

    if (condition) {
      $('#special_hour_allow_booking').val('true')
      $('#special_hour_allow_booking').prop( "disabled", true );
      $('<input>').attr({type: 'hidden', id: 'hidden_allow_booking', name: 'special_hour[allow_booking]',
        value: 'true'}).appendTo('.special_hour_allow_booking');
    } else {
      $('#special_hour_allow_booking').prop( "disabled", false );
      $('#hidden_allow_booking').remove()
    }

    if (event.target.selectedOptions == undefined) {
      $('.facilities-for-special-hours').toggle(condition);
    }

    const startDateTarget = $('#special_hour_start_date');
    const endDateTarget = $('#special_hour_end_date');
    updateEndDate(startDateTarget, endDateTarget);
  }

  $('input[name="special_hour[all_facilities]"]').on('change', toggleFacilities);
  $('select[name="special_hour[week_days][]').on('change', toggleFacilities);

  const facilitiesEl =  $('input[name="special_hour[all_facilities]"]:checked')[0];

  if (facilitiesEl) { 
    toggleFacilities({target: facilitiesEl});
  }

  $.each($('ul.special_hour_facilities .parent-checkbox'), function() {
    const checked = $(this).is(':checked');
    const ul = $(this).siblings('ul');

    ul.toggle(checked);
  })

  $('ul.special_hour_facilities .parent-checkbox').on('click', function() {
    const checked = $(this).is(':checked');
    const ul = $(this).siblings('ul');

    if (ul) {
      ul.toggle(checked);
      ul.find('.child-checkbox').prop('checked', checked)
    }
  })

  $('#special_hour_opened').prop('checked', true).on('click', (e) => e.preventDefault());

  const toggleOffPeak = () => {
    const disabled = !$('#special_hour_off_peak_enabled').is(':checked')
    $('#off_peek_inputs select, #off_peek_inputs input').prop("disabled", disabled);
  };

  $('#special_hour_off_peak_enabled').on('change', toggleOffPeak);

  toggleOffPeak();

  const select = $('#special_hour_day_type');

  function toggleDayType() {
    const openingHoursContainer = select.parents('form').find('.opening_hours_container');
    const allowBookingContainer = select.parents('form').find('.special_hour_allow_booking');

    openingHoursContainer.toggle(select.val() === 'alternative');
    allowBookingContainer.toggle(select.val() !== 'alternative');

    $('.info-icon-allow-booking-holiday').toggle(select.val() === 'holidays');
    $('.info-icon-allow-booking-closure').toggle(select.val() === 'closure');
  }

  select.on('change', toggleDayType);
  toggleDayType();

  function specialHourRequestFormValidation() {
    var submitBtn = $('#submit_special_date');
    var modalWindow =  $('.special_hour_form').closest('#modal_form');

    $('#special_date_form_btn').on('click', function(e) {
      e.preventDefault();

      if ($('.type-venue', modalWindow).length > 0) {
        if ($('.type-venue:checked', modalWindow).length == 0) {
          alert("At least 1 venue must be selected.");
          return false;
        }
      }

      if ($('#special_hour_allow_booking').val() == 'false') {
        const MESSAGE = "Are you sure?\n\nBy setting this to 'No', you are stopping bookings for ALL " +
                        "dates being made during this period. This option should only " +
                        "be used if you/your colleagues are not working during an extended " +
                        "period e.g. summer holidays and don't want customers to make bookings " +
                        "which will then auto-reject as they aren't approved in time."

        if (!confirm(MESSAGE)) {
          return false;
        } else {
          submitBtn.click();
        }
      }
      submitBtn.click();
    });
  }

  specialHourRequestFormValidation();

  $('#week_days').multiselect({
    nonSelectedText: 'None',
    buttonClass: 'input-sm form-control'
  })
}